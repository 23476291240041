import { useRef } from "react";
import { Bike } from "./Bike";
import { Drinks } from "./Drinks";
import { Hiker } from "./Hiker";
import { Horse } from "./Horse";
import { Kayak } from "./Kayak";
import { Scuba } from "./Scuba";

export * from "./Bike";
export * from "./Drinks";
export * from "./Hiker";
export * from "./Horse";
export * from "./Kayak";
export * from "./Scuba";

const IllustrationArray = [Bike, Drinks, Hiker, Horse, Kayak, Scuba];

export const useRandomIllustration = () => {
  const indexRef = useRef(Math.floor(Math.random() * 6));
  return IllustrationArray[indexRef.current];
};